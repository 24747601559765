// Generated by Framer (9f68555)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import App from "https://framerusercontent.com/modules/L34ASyZw73LWpEM52h8l/ZxMSLNZ3qN2kiy0BH9EI/Superscript.js";
const AppFonts = getFonts(App);

const cycleOrder = ["eHGvE4X5p", "Y1zbPkNFR", "AP_Vp9do1"];

const serializationHash = "framer-51MkY"

const variantClassNames = {AP_Vp9do1: "framer-v-yrtrxs", eHGvE4X5p: "framer-v-og4ysw", Y1zbPkNFR: "framer-v-eisojh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Desktop: "eHGvE4X5p", Phone: "AP_Vp9do1", Tablet: "Y1zbPkNFR"}

const getProps = ({fontFamily, height, id, letterSpacing, lineHeight, superscriptColor, superscriptFontSizeDesktop, superscriptFontSizePhone, superscriptFontSizeTablet, superscriptText, text, textColor, textFontSizeDesktop, textFontSizePhone, textFontSizeTablet, width, ...props}) => { return {...props, aoxH6kkf7: superscriptColor ?? props.aoxH6kkf7 ?? "rgb(87, 87, 87)", dyUre2flx: textFontSizeDesktop ?? props.dyUre2flx ?? 28, Fw9l1imOk: textColor ?? props.Fw9l1imOk ?? "rgb(0, 0, 0)", IL9uILrxT: superscriptFontSizeTablet ?? props.IL9uILrxT ?? 14, ImM5Dt3Tn: textFontSizeTablet ?? props.ImM5Dt3Tn ?? 24, n8yxcyBov: letterSpacing ?? props.n8yxcyBov, Opwo1VWdJ: text ?? props.Opwo1VWdJ ?? "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer semper efficitur erat eget hendrerit.", qyUkyfJU6: superscriptFontSizePhone ?? props.qyUkyfJU6 ?? 12, RfYrXYvzv: superscriptText ?? props.RfYrXYvzv ?? "8,9,10", ro89tgspa: fontFamily ?? props.ro89tgspa ?? "Inter", RxFgssaoV: lineHeight ?? props.RxFgssaoV ?? 1.4, sp9c1lakW: textFontSizePhone ?? props.sp9c1lakW ?? 20, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "eHGvE4X5p", xFjznAi7_: superscriptFontSizeDesktop ?? props.xFjznAi7_ ?? 18} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;text?: string;superscriptText?: string;fontFamily?: string;textColor?: string;superscriptColor?: string;textFontSizeDesktop?: number;textFontSizeTablet?: number;textFontSizePhone?: number;superscriptFontSizeDesktop?: number;superscriptFontSizeTablet?: number;superscriptFontSizePhone?: number;lineHeight?: number;letterSpacing?: number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Opwo1VWdJ, RfYrXYvzv, ro89tgspa, Fw9l1imOk, aoxH6kkf7, dyUre2flx, ImM5Dt3Tn, sp9c1lakW, xFjznAi7_, IL9uILrxT, qyUkyfJU6, RxFgssaoV, n8yxcyBov, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "eHGvE4X5p", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-og4ysw", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"eHGvE4X5p"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({AP_Vp9do1: {"data-framer-name": "Phone"}, Y1zbPkNFR: {"data-framer-name": "Tablet"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-kmve3o-container"} layoutDependency={layoutDependency} layoutId={"Mx58iNYqF-container"}><App color={Fw9l1imOk} fontFamily={ro89tgspa} fontSize={dyUre2flx} height={"100%"} id={"Mx58iNYqF"} layoutId={"Mx58iNYqF"} letterSpacing={n8yxcyBov} lineHeight={RxFgssaoV} style={{width: "100%"}} superscriptColor={aoxH6kkf7} superscriptFontSize={xFjznAi7_} superscriptText={RfYrXYvzv} text={Opwo1VWdJ} width={"100%"} {...addPropertyOverrides({AP_Vp9do1: {fontSize: sp9c1lakW, superscriptFontSize: qyUkyfJU6}, Y1zbPkNFR: {fontSize: ImM5Dt3Tn, superscriptFontSize: IL9uILrxT}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-51MkY.framer-1cwjs4b, .framer-51MkY .framer-1cwjs4b { display: block; }", ".framer-51MkY.framer-og4ysw { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 28px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 800px; }", ".framer-51MkY .framer-kmve3o-container { flex: none; height: auto; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-51MkY.framer-og4ysw { gap: 0px; } .framer-51MkY.framer-og4ysw > * { margin: 0px; margin-bottom: calc(28px / 2); margin-top: calc(28px / 2); } .framer-51MkY.framer-og4ysw > :first-child { margin-top: 0px; } .framer-51MkY.framer-og4ysw > :last-child { margin-bottom: 0px; } }", ".framer-51MkY.framer-v-eisojh.framer-og4ysw { gap: 24px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-51MkY.framer-v-eisojh.framer-og4ysw { gap: 0px; } .framer-51MkY.framer-v-eisojh.framer-og4ysw > * { margin: 0px; margin-bottom: calc(24px / 2); margin-top: calc(24px / 2); } .framer-51MkY.framer-v-eisojh.framer-og4ysw > :first-child { margin-top: 0px; } .framer-51MkY.framer-v-eisojh.framer-og4ysw > :last-child { margin-bottom: 0px; } }", ".framer-51MkY.framer-v-yrtrxs.framer-og4ysw { gap: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-51MkY.framer-v-yrtrxs.framer-og4ysw { gap: 0px; } .framer-51MkY.framer-v-yrtrxs.framer-og4ysw > * { margin: 0px; margin-bottom: calc(20px / 2); margin-top: calc(20px / 2); } .framer-51MkY.framer-v-yrtrxs.framer-og4ysw > :first-child { margin-top: 0px; } .framer-51MkY.framer-v-yrtrxs.framer-og4ysw > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 78.5
 * @framerIntrinsicWidth 800
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"Y1zbPkNFR":{"layout":["fixed","auto"]},"AP_Vp9do1":{"layout":["fixed","auto"]}}}
 * @framerVariables {"Opwo1VWdJ":"text","RfYrXYvzv":"superscriptText","ro89tgspa":"fontFamily","Fw9l1imOk":"textColor","aoxH6kkf7":"superscriptColor","dyUre2flx":"textFontSizeDesktop","ImM5Dt3Tn":"textFontSizeTablet","sp9c1lakW":"textFontSizePhone","xFjznAi7_":"superscriptFontSizeDesktop","IL9uILrxT":"superscriptFontSizeTablet","qyUkyfJU6":"superscriptFontSizePhone","RxFgssaoV":"lineHeight","n8yxcyBov":"letterSpacing"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramervThZYVl2I: React.ComponentType<Props> = withCSS(Component, css, "framer-51MkY") as typeof Component;
export default FramervThZYVl2I;

FramervThZYVl2I.displayName = "Superscipt";

FramervThZYVl2I.defaultProps = {height: 78.5, width: 800};

addPropertyControls(FramervThZYVl2I, {variant: {options: ["eHGvE4X5p", "Y1zbPkNFR", "AP_Vp9do1"], optionTitles: ["Desktop", "Tablet", "Phone"], title: "Variant", type: ControlType.Enum}, Opwo1VWdJ: {defaultValue: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer semper efficitur erat eget hendrerit.", displayTextArea: true, title: "Text", type: ControlType.String}, RfYrXYvzv: {defaultValue: "8,9,10", title: "Superscript Text", type: ControlType.String}, ro89tgspa: {defaultValue: "Inter", displayTextArea: false, title: "Font Family", type: ControlType.String}, Fw9l1imOk: {defaultValue: "rgb(0, 0, 0)", title: "Text Color", type: ControlType.Color}, aoxH6kkf7: {defaultValue: "rgb(87, 87, 87)", title: "Superscript Color", type: ControlType.Color}, dyUre2flx: {defaultValue: 28, max: 72, min: 6, step: 1, title: "Text Font Size | Desktop", type: ControlType.Number}, ImM5Dt3Tn: {defaultValue: 24, max: 72, min: 6, step: 1, title: "Text Font Size | Tablet", type: ControlType.Number}, sp9c1lakW: {defaultValue: 20, max: 72, min: 6, step: 1, title: "Text Font Size | Phone", type: ControlType.Number}, xFjznAi7_: {defaultValue: 18, max: 24, min: 6, step: 1, title: "Superscript Font Size | Desktop", type: ControlType.Number}, IL9uILrxT: {defaultValue: 14, max: 24, min: 6, step: 1, title: "Superscript Font Size | Tablet", type: ControlType.Number}, qyUkyfJU6: {defaultValue: 12, max: 24, min: 6, step: 1, title: "Superscript Font Size | Phone", type: ControlType.Number}, RxFgssaoV: {defaultValue: 1.4, max: 3, min: 0.5, step: 0.1, title: "Line Height", type: ControlType.Number}, n8yxcyBov: {defaultValue: 0, max: 0.5, min: -0.1, step: 0.01, title: "Letter Spacing", type: ControlType.Number}} as any)

addFonts(FramervThZYVl2I, [{explicitInter: true, fonts: []}, ...AppFonts], {supportsExplicitInterCodegen: true})